
<template>
  <div>
    <layout>
      <div class="search-top">
        <div class="contant">
          <div class="contant-top">
            <div class="contant-logo">
              <img src="@/assets/home/logo.png" alt />
              <span>爱玩就上爱租号</span>
            </div>
            <div class="contant-search">
              <input type="text" placeholder="搜你想要的~" />
              <button>搜索</button>
            </div>
          </div>
          <div class="contant-bottom">
            <div class="contant-hot">
              <img src="@/assets/home/hot.png" alt />
              <span>热门游戏</span>
            </div>
            <ul>
              <li :class="{active: isShow == 0}" @click="toClick(0)">首页</li>
              <li :class="{active: isShow == 1}" @click="toClick(1)">爱租号</li>
              <li :class="{active: isShow == 2}" @click="toClick(2)">工具下载</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="succese">
        <div class="succese-contants">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">爱租号首页</el-breadcrumb-item>
            <el-breadcrumb-item>支付成功</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="succese-box card">
            <div class="succese-left">
              <img v-if="detailsList.new_channel==1" :src= goods_info.game_img alt />
              <img v-if="detailsList.new_channel==2" :src= goods_info.data.list[0].game_img alt />
              <div class="left-title">
                <p>订单支付成功</p>
                <span>首次租赁，请参照下方上号流程上号。</span>
                <div class="black">
                  <span @click="toClick(0)">返回首页</span>
                  <span style="margin:0 10px;">|</span>
                  <!-- <span class="black-active"></span> -->
                 <router-link tag="span"  :to="{name: 'myOrder'}">查看租赁订单</router-link>
                </div>
              </div>
            </div>
            <div>
            <div class="succese-right" v-if="!game_login_mode&&is_over">
                <div class="succese-login">
                    <span>登录:</span>
                    <p>{{detailsList.pay_no}}</p>
                    <button type="button" class="buttons"
                              v-clipboard:copy="detailsList.pay_no"
                              v-clipboard:success="onCopy"
                              v-clipboard:error="onError">复制</button>
                </div>
            </div>
            <div class="succese-right" v-if="game_login_mode">
            <div class="succese-login">
              <span>帐号:</span>
              <p>{{pay_result.game_account}}</p>
              <button type="button" class="buttons"
                        v-clipboard:copy="pay_result.game_account"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError">复制</button>
            </div>
              <div class="succese-login">
              <span>密码:</span>
              <p>{{pay_result.game_pwd}}</p>
              <button type="button" class="buttons"
                        v-clipboard:copy="pay_result.game_pwd"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError">复制</button>
            </div>
            </div>
            <div class="succese-time">
              <span>租赁时间：</span>
              <p>{{detailsList.created_at}}至{{detailsList.end_time}}</p>
            </div>
            </div>
           
            </div>
          <!-- </div> -->
          <div class="succese-img card">
            <div class="succese-title">
              <div class="txt">上号器登录</div>
              <div class="succese-btn">
                <p>下载上号器</p>
                <el-button type="primary" icon="el-icon-download" round >
                  <a href="https://c.ul.cn/8a" v-if="detailsList.new_channel==1"> 点此下载</a>
                  <a href="https://c.ul.cn/8O" v-if="detailsList.new_channel==2"> 点此下载</a>
                  </el-button>
                <span>提醒：安装上号器之前先关闭所有杀毒软件</span>
              </div>
              <div class="succese-txt">
                <p>如自动更新失败或循环更新等异常情况，请重新下载最新版本上号器。</p>
                <p v-if="false">
                  无法正常下载可以尝试一下备用下载地址：<a href="https://www.aizuhao.com.cn/9898">https://www.aizuhao.com.cn/9898</a>
                </p>
              </div>
              <div class="succese-p">
                <p>使用登录码登录（如下图）</p>
              </div>
            </div>
            <img src="../../assets/succese/上号教程.png" alt />
          </div>
        </div>
      </div>

       <side-Bar></side-Bar>
    </layout>
  </div>
</template>
 
<script>
import sideBar from "../../components/common/sidebar";

import layout from "@/components/layout";
export default {
  name: "order",
  data() {
    return {
            goods_info:[],
      detailsList: {},
      pay_result:{},
      isShow: 1,
      is_over:true,
      game_login_mode:false
    };
  },
  components: {
    layout,
    sideBar
  },
  created() {
    this.getDetailsList()
  },
  methods: {
  
      toClick(id) {
            if(id == 0){
         this.$router.push({
          path:'/home'
        })
      }else if(id == 2) {
        this.$router.push({
          path:'/download'
        })
      }else{
           this.$router.push({
          path:'/lobby'
        })
      }
          this.isShow = id
      },

    onCopy: function (e) {
    this.$message.success('复制成功');
       //alert('You just copied: ' + e.text)
    },
    onError: function (e) {
      this.$message.error('复制失败');
    },

    // 获取订单详情
     getDetailsList () {
      let order_no = this.$route.params.id
      let user_id = this.$store.state.user_id
      this.$get('api/order/detail', { user_id, order_no }).then(res => {
        console.log(res,'success');
        if (res.code == 0) {
          this.detailsList = res.ret
          this.pay_result = this.detailsList.pay_result
          this.goods_info = res.ret.goods_info
          if(this.detailsList.pay_result.gameLoginType=='1'){
            this.game_login_mode=true;
          }else{
            this.game_login_mode=false;
          }
          // if(this.detailsList.status>2){
          //   this.game_login_mode=false;
          //   this.is_over=false;
          // }
        }
      })
    },

 
    },

};
</script>
<style lang='scss' scoped>
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 40px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
        .active:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}

.succese {
  width: 100%;
  padding-bottom: 66px;
  background-color: #f0f2f5;
  .succese-contants {
    width: 1200px;
    margin: 0 auto;
    .el-breadcrumb {
      padding: 25px 0;
    }
    .succese-img {
      padding: 38px 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .succese-title {
        .txt {
          position: relative;
          font-size: 20px;
          font-weight: bold;
          color: #000000;
          padding-left: 20px;
          &::before {
            content: "";
            position: absolute;
            top: 6px;
            left: 0px;
            width: 4px;
            height: 17px;
            background: #3c7efe;
            border-radius: 2px;
          }
        }
        .succese-btn {
          display: flex;
          height: 40px;
          line-height: 60px;
          padding-left: 53px;
          margin: 40px 0;
          p {
            font-size: 16px;
            color: #000;
            position: relative;
            &::before{
                content: "";
                position: absolute;
                top: -6px;
                left: -27px;
                width: 40px;
                height: 40px;
                 background: url(../../assets/succese/01.png);
                background-size: 100% 100%;
            }
          }
          span {
            font-size: 12px;
            color: #fc3838;
          }
          .el-button{
              margin: 0 40px;
          }
        }
        .succese-txt{
            font-size: 13px;
            color: #848A9E;
             padding-left: 53px;
            p{
                a{
                    color: #426CBE;
                }
            }
        }
        .succese-p{
             padding-left: 53px;
             margin: 40px 0;
          p {
            font-size: 16px;
            color: #000;
            position: relative;
            &::before{
                content: "";
                position: absolute;
                top: -17px;
                left: -27px;
                width: 40px;
                height: 40px;
                 background: url(../../assets/succese/02.png);
                background-size: 100% 100%;
            }
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .succese-box {
      padding: 45px 30px;
      display: flex;
      align-items: center;
      margin: 0 0 15px;
      .succese-left {
        display: flex;
        align-items: center;
        //   justify-content: space-between;
        width: 452px;
        border-right: 1px dashed #848a9e;
        img {
          width: 80px;
          height: 80px;
          margin-right: 20px;
          border-radius: 12px;
        }
        .left-title {
          p {
            font-size: 18px;
            font-weight: bold;
            color: #000000;
          }
          span {
            font-size: 12px;
            color: #848a9e;
          }
          .black {
            cursor: pointer;
            span {
              font-size: 12px;
              color: #010101;
              padding: 5px 0;
            }
            .black-active {
              color: #426cbe;
            }
          }
        }
      }
      .succese-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 47px;
        .succese-login {
          display: flex;
          align-items: center;
          font-size: 16px;
          margin-bottom: 15px;
          span {
            color: #848a9e;
          }
          p {
            width: 150px;
            color: #000;
            margin-right: 20px;
          }
        }
       
      }
       .succese-time {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #a2a6b8;
          margin-left: 47px;
        }
    }
  }
}
//
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  color: #303133;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
}

.buttons {
  width: 90px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 24px;
  background-color: #3c7efe;
  color: #fff;
  cursor: pointer;
}
</style>